import { useState, useEffect, useMemo } from 'react';
import { Grid, Box, FormHelperText, Divider, Typography, Button } from '@mui/material';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { DEFAULT_NO_ERROR, requiredCUITCUILValidator, requiredValidator } from 'helpers/Validators/Validators';
import { getUserAfip } from 'services';
import { Select } from '../select/Select';
import SearchIcon from '@mui/icons-material/Search';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

export const LABELS_SEARCH_ENTITY_FORM = {
	titulo: 'Donatario',
	titulo2: 'Proveedor',
	CUIT_CUIL: 'N° de CUIT/CUIL',
	name: 'Nombre y Apellido/Razón Social',
	address: 'Dirección Fiscal',
	entity: 'Persona u Organismo no encontrado',
	dni: 'N° de DNI'
};

export interface ISearchEntityData {
	identifier: string | undefined;
	name: string | undefined;
	address: string | undefined;
	user: any | undefined;
	hasErrors: boolean;
	dni?: string;
	errors?: any;
	hasErrorAFIP?: boolean;
	documentType?: string;
	donor_name: string | undefined;
}

interface SearchEntityFormProps {
	identifier: string | undefined;
	name: string | undefined;
	address: string | undefined;
	user: any | undefined;
	handleChange(data: ISearchEntityData): void;
	registerTypeId?: number;
	disableDni?: boolean;
	defaultDocumentOption?: ISelectOption;
	donor_name: string | undefined;
}

export interface IErrorsSearchEntityForm {
	identifier?: string | undefined;
	name?: string | undefined;
	address?: string | undefined;
	user?: string | undefined;
}

const SearchEntityComponent = ({
	identifier,
	name,
	address,
	donor_name,
	handleChange,
	registerTypeId,
	disableDni,
	defaultDocumentOption
}: SearchEntityFormProps) => {
	const options = [
		{ label: 'DNI', disabled: disableDni },
		{ label: 'CUIT/CUIL' },
		...(registerTypeId === 3 ? [{ label: 'No requerido' }] : [])
	];
	const [userCUITCUIL, setUserCUITCUIL] = useState<string | undefined>('');
	const [userName, setUserName] = useState<string | undefined>('');
	const [userAddress, setUserAddress] = useState<string | undefined>('');
	const [findedUser, setFindedUser] = useState<any | undefined>();
	const [hasAfipErrorFromData, setHasAfipErrorFromData] = useState<boolean | undefined>(false);
	const [dataFormHasChanged, setDataFormHasChanged] = useState<number>(0);
	const [enableEditUser, setEnableEditUser] = useState<boolean | undefined>(false);
	const [errors, setErrors] = useState<IErrorsSearchEntityForm>({} as IErrorsSearchEntityForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isAfipIsFound, setIsAfipIsFound] = useState(false);
	const [documentType, setDocumentType] = useState(defaultDocumentOption || options[1]);
	const [dniNumber, setDniNumber] = useState('');
	const [donorName, setDonorName] = useState('');

	const [hasErrorAFIP, setHasErrorAFIP] = useState(false);
	const [afipDataSource, setAfipDataSource] = useState('');
	const [isAutocompleted, setIsAutocompleted] = useState(false);

	useEffect(() => {
		if (identifier) {
			setUserCUITCUIL(identifier);
			setDniNumber(identifier);
			setIsAutocompleted(identifier.length > 10);
		}
	}, [identifier]);

	useEffect(() => {
		if (donor_name) {
			setDonorName(donor_name as any);
		}
	}, [donor_name]);

	useEffect(() => {
		name && setUserName(name);
	}, [name]);

	useEffect(() => {
		address && setUserAddress(address);
	}, [address]);

	const showClearButton = useMemo(() => {
		return identifier && address && name;
	}, [identifier, address, name]);

	const validateForm = ({
		userCUITCUIL,
		userName,
		userAddress,
		dniNumber
	}: {
		[k: string]: string | undefined;
	}): boolean => {
		if (dataFormHasChanged <= 1 || isLoading) return true;
		const newErrors: IErrorsSearchEntityForm = {} as IErrorsSearchEntityForm;

		setErrors(newErrors);

		let dniNumberIdentifier =
			(dniNumber?.toString()?.trim() || '').length < 7
				? `El campo ${LABELS_SEARCH_ENTITY_FORM.dni} debe contener mínimo 7 caracteres.`
				: DEFAULT_NO_ERROR;

		if (dniNumberIdentifier !== DEFAULT_NO_ERROR && documentType?.label === 'DNI') {
			newErrors.identifier = dniNumberIdentifier;
			setErrors(newErrors);
			return true;
		}

		if (!userCUITCUIL) return true;

		let identifierError = requiredCUITCUILValidator({
			value: userCUITCUIL,
			label: LABELS_SEARCH_ENTITY_FORM.CUIT_CUIL
		});

		if (identifierError !== DEFAULT_NO_ERROR && documentType?.label !== 'DNI') {
			newErrors.identifier = identifierError;
		}

		const nameError = requiredValidator({ value: userName, label: LABELS_SEARCH_ENTITY_FORM.name });
		if (nameError !== DEFAULT_NO_ERROR) {
			newErrors.name = nameError;
		}

		const addressError = requiredValidator({ value: userAddress, label: LABELS_SEARCH_ENTITY_FORM.address });
		if (addressError !== DEFAULT_NO_ERROR) {
			newErrors.address = addressError;
		}

		setErrors(newErrors);
		if (Object.entries(newErrors).length !== 0) {
			return false;
		}

		return true;
	};

	const getParsedData = (hasErrors: boolean): ISearchEntityData => {
		return {
			identifier: userCUITCUIL?.trim(),
			name: userName?.trim(),
			address: userAddress?.trim(),
			user: findedUser,
			hasErrors: hasErrors,
			errors: hasErrors ? errors : ({} as IErrorsSearchEntityForm),
			dni: dniNumber,
			donor_name: donorName,
			hasErrorAFIP,
			documentType: documentType.label
		} as ISearchEntityData;
	};

	useEffect(() => {
		if (!userCUITCUIL) {
			setUserName('');
			setUserAddress('');
			validateForm({ userCUITCUIL, userAddress, userName });
		}
	}, [userCUITCUIL]);

	useEffect(() => {
		if (findedUser) {
			setUserName(findedUser.nombre);
			setUserAddress(findedUser.domicilio);
			setDataFormHasChanged(dataFormHasChanged + 1);
		}
	}, [findedUser]);

	const resetInputs = () => {
		setDniNumber('');
		setDonorName('');
		setUserCUITCUIL('');
		setFindedUser(undefined);
		setUserName('');
		setUserAddress('');
		setErrors({});
		setHasErrorAFIP(false);
		setIsAfipIsFound(false);
		setAfipDataSource('');
		setEnableEditUser(false);
	};

	const searchUser = async () => {
		if (isLoading || !userCUITCUIL) return;

		const documentNumber = userCUITCUIL;
		resetInputs();
		setUserCUITCUIL(documentNumber);

		try {
			setIsLoading(true);
			const userData = await getUserAfip(documentNumber);
			setFindedUser({
				domicilio: userData.fiscal_address.address,
				nombre: `${userData.name} ${userData.last_name}`
			});
			setAfipDataSource(`Validado por ${userData.data_source}`);
			setHasAfipErrorFromData(userData.has_error_afip);
		} catch {
			setHasErrorAFIP(true);
			setEnableEditUser(true);

			setErrors({ identifier: 'Ingrese manualmente los campos requeridos.' });
			setAfipDataSource('CUIT no validado, controle los datos a ingresar');

			setUserName('');
			setUserAddress('');
		} finally {
			setIsLoading(false);
			setIsAfipIsFound(true);
			setDataFormHasChanged((v) => v + 1);
		}
	};

	useEffect(() => {
		setEnableEditUser(hasAfipErrorFromData);
	}, [hasAfipErrorFromData]);

	useEffect(() => {
		if (dataFormHasChanged > 0) {
			const hasErrors = !validateForm({ userCUITCUIL, userAddress, userName, dniNumber });
			const dataToSend = getParsedData(hasErrors);
			handleChange(dataToSend);
		}
	}, [dataFormHasChanged, dniNumber]);

	useEffect(() => {
		if (isAutocompleted) searchUser();
	}, [isAutocompleted]);

	const selectedTitle = { 1: LABELS_SEARCH_ENTITY_FORM.titulo2, 3: LABELS_SEARCH_ENTITY_FORM.titulo };

	return (
		<>
			<Typography
				variant="subtitle2"
				fontSize={'1em'}
				style={{ paddingLeft: '25px' }}>
				{selectedTitle[registerTypeId as keyof typeof selectedTitle]}
			</Typography>
			<Divider className="customDividerLight" />

			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={4}
				xl={4}>
				<Select
					label="Tipo de Documento"
					disabled={disableDni || isLoading}
					options={options}
					value={documentType}
					onChange={(value) => {
						setDocumentType(value as ISelectOption);
						resetInputs();
						setDataFormHasChanged((v) => v + 1);
					}}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={8}
				xl={8}>
				<Box sx={{ display: 'flex', gap: 2 }}>
					{documentType.label === 'No requerido' ? null : documentType.label === 'CUIT/CUIL' ? (
						<>
							<FormGenericInput
								maxLength={11}
								disabled={isLoading || isAfipIsFound || (!!userCUITCUIL?.length && !!userName && !!userAddress)}
								value={userCUITCUIL}
								type="number"
								label={LABELS_SEARCH_ENTITY_FORM.CUIT_CUIL}
								name="cuit"
								id="cuit"
								hint={afipDataSource}
								onChange={(value) => {
									setUserCUITCUIL(value as string);
									setDataFormHasChanged(dataFormHasChanged + 1);
								}}
								size={'medium'}
								required={true}
								error={errors?.identifier}
								endAdornment={
									isAfipIsFound || showClearButton ? (
										<LoadingButton
											loading={isLoading}
											disabled={isLoading}
											onClick={() => {
												resetInputs();
												setDataFormHasChanged(dataFormHasChanged + 1);
											}}
											size="small"
											variant="contained"
											color="primary"
											sx={{ minWidth: 40 }}>
											<CloseIcon />
										</LoadingButton>
									) : (
										<LoadingButton
											loading={isLoading}
											disabled={isLoading}
											sx={{ minWidth: 40 }}
											variant="contained"
											color="primary"
											onClick={searchUser}
											size="small">
											<SearchIcon sx={{ fontSize: '12px' }} />
										</LoadingButton>
									)
								}
							/>
							{errors?.user !== undefined && (
								<Box sx={{ alignSelf: 'center', mb: 2, width: '100%' }}>
									<FormHelperText
										id={'userError'}
										error={true}>
										{errors.user}
									</FormHelperText>
								</Box>
							)}

							<FormGenericInput
								value={userName}
								label={LABELS_SEARCH_ENTITY_FORM.name}
								name="nombre"
								id="nombre"
								onChange={(value: string) => {
									setUserName(value);
									setDataFormHasChanged((v) => v + 1);
								}}
								size={'medium'}
								required={true}
								disabled={!enableEditUser || isLoading}
								error={errors?.name}
							/>

							<FormGenericInput
								value={userAddress}
								label={LABELS_SEARCH_ENTITY_FORM.address}
								name="domicilioUnico"
								id="domicilioUnico"
								onChange={(value: string) => {
									setUserAddress(value);
									setDataFormHasChanged(dataFormHasChanged + 1);
								}}
								size={'medium'}
								required={true}
								disabled={!enableEditUser || isLoading}
								error={errors?.address}
							/>
						</>
					) : (
						<>
							<Box sx={{ width: '30%' }}>
								<FormGenericInput
									maxLength={8}
									type="number"
									value={dniNumber}
									label={LABELS_SEARCH_ENTITY_FORM.dni}
									onChange={(value: string) => {
										setDniNumber(value);
										setDataFormHasChanged((v) => v + 1);
									}}
									size={'medium'}
									required={true}
									error={errors?.identifier}
								/>
							</Box>

							<Box sx={{ width: '70%' }}>
								<FormGenericInput
									value={donorName}
									label={LABELS_SEARCH_ENTITY_FORM.name}
									onChange={(value: string) => {
										setDonorName(value);
										setDataFormHasChanged((v) => v + 1);
									}}
									size={'medium'}
									required={true}
								/>
							</Box>
						</>
					)}
				</Box>
			</Grid>
		</>
	);
};

SearchEntityComponent.displayName = 'SearchEntityComponent';

export { SearchEntityComponent };
