/* eslint-disable max-lines */
import { useState, useMemo, useEffect, FC } from 'react';
import { IFiltersMovementTable, IMovTableProps } from 'interfaces/movements/IMovTableProps';
import { IMovementEdit } from 'interfaces/movements/MovementEdit';
import { ApproveMovement } from 'pages/movement/approveMovement/ApproveMovement';
import { ApproveDownMovement } from 'pages/movement/approveMovement/ApproveDownMovement';
import { DeleteMovement } from 'pages/movement/deleteMovement/DeleteMovement';
import { UploadMinuteSigned } from 'pages/movement/uploadMinuteSigned/UploadMinuteSigned';
import { FormControl, Box, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, Button } from '@mui/material';
import { usePromiseTracker } from 'react-promise-tracker';
import { useMovContext } from 'hooks/useMovContext';
import './movements-table.css';
import {
	useReactTable,
	ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	FilterFn,
	Row,
	flexRender,
	Cell
} from '@tanstack/react-table';
import ClearIcon from '@mui/icons-material/Clear';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';

import { defaultColumns } from './MovColumnDef';
import { TablePaginator } from 'components/globals/paginator/TablePaginator';
import { MovementsStatus, MovementType, PropertyType } from 'enums';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { OpenInBrowser } from '@mui/icons-material';
import { AppDateRangePicker, DateRange } from 'components/globals/AppDateRangePicker/AppDateRangePicker';
import dayjs from 'dayjs';
import PropertyMovementTimeline from 'components/globals/movementTimeline/PropertyMovementTimeline';
import { generatePdfMovement } from 'services/Movements/crudMovements';
import { getAfipUser } from 'services';
import { Switch } from 'components/globals/switch/Switch';

declare module '@tanstack/table-core' {
	interface FilterFns {
		fuzzy: FilterFn<unknown>;
	}
	interface FilterMeta {
		itemRank: RankingInfo;
	}
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value);

	// Store the itemRank info
	addMeta({
		itemRank
	});

	// Return if the item should be filtered in/out
	return itemRank.passed;
};

export const MovementsTable: FC<IMovTableProps> = ({
	setEditMovement,
	movements,
	handleShow,
	handleEditDeregistration,
	handleShowDeregistration,
	documentType
}) => {
	const movContext = useMovContext();
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [rowSelection, setRowSelection] = useState({});
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);
	const [data, setData] = useState<IMovementEdit[]>([]);

	const [openModalApprove, setOpenModalApprove] = useState(false);
	const [openModalDelete, setOpenModalDelete] = useState(false);
	const [openModalMinuteSigned, setOpenModalMinuteSigned] = useState(false);
	const [isDownMovement, setIsDownMovement] = useState(false);
	const [selectedRow, setSelectedRow] = useState<IMovementEdit | null>(null);

	// Filters
	const [myGlobalsFilters, setMyGlobalsFilters] = useState<any>({});
	const [movementTypeFilter, setMovTypeFilter] = useState<any>('');
	const [stateFilter, setStateFilter] = useState<any>(0);
	const { promiseInProgress } = usePromiseTracker({ area: 'getMovementsTracker' });
	const [showMovementFilesModal, setShowMovementFilesModal] = useState(false);
	const [dateRange, setDateRange] = useState<DateRange>([null, null]);
	const [showObservationsHistory, setShowObsertavionsHistory] = useState(false);
	const [isAscendantDate, setIsAscendantDate] = useState(false);
	const [isAscendanMinuteNum, setIsAscendanMinuteNum] = useState(false);
	const [isChecked, setIsChecked] = useState(true);

	useEffect(() => setData(movements), [movements]);

	const table = useReactTable({
		data,
		columns: documentType === 'bajas' ? columns.filter((el: any) => el.accessorKey !== 'minute_number') : columns,
		filterFns: {
			fuzzy: fuzzyFilter
		},
		state: {
			columnFilters,
			globalFilter,
			rowSelection
		},
		onRowSelectionChange: setRowSelection,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: fuzzyFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getRowId: (row) => row.id
	});

	useEffect(() => {
		const serverFilters: IFiltersMovementTable = {};
		serverFilters.page = movContext?.filtersMovTable?.page || 1;
		serverFilters.limit = movContext?.filtersMovTable?.limit || 10;
		if (movContext?.filtersMovTable?.date_movement) {
			serverFilters.date_movement = movContext?.filtersMovTable?.date_movement;
		}
		if (movementTypeFilter.trim().length > 0) {
			if (movementTypeFilter === 'Alta') {
				serverFilters.movement_type = MovementType.ALTA;
			}
			if (movementTypeFilter === 'Baja') {
				serverFilters.movement_type = MovementType.BAJA;
			}
		}

		serverFilters.movement_status = !stateFilter ? undefined : Number(stateFilter);

		// Busca por numero de acta
		if (myGlobalsFilters?.minute_number) {
			serverFilters.minute_number = String(myGlobalsFilters?.minute_number);
		}
		// Busca por numero de inventario
		if (myGlobalsFilters?.inventoryNumber) {
			serverFilters.inventory_number = String(myGlobalsFilters?.inventoryNumber);
		}
		// Busca por expediente
		if (myGlobalsFilters?.proceedings_number) {
			serverFilters.proceedings_number = String(myGlobalsFilters?.proceedings_number);
		}
		// Busca por instrumento legal
		if (myGlobalsFilters?.legal_instrument) {
			serverFilters.legal_instrument = String(myGlobalsFilters?.legal_instrument);
		}
		// Buscar por Sub tipo de movimiento
		if (myGlobalsFilters?.movement_sub_type) {
			serverFilters.movement_sub_type = String(myGlobalsFilters?.movement_sub_type);
		}

		if (myGlobalsFilters?.movement_date) {
			serverFilters.movement_date = String(myGlobalsFilters?.movement_date);
		}

		if (myGlobalsFilters?.movement_date_end) {
			serverFilters.movement_date_end = String(myGlobalsFilters?.movement_date_end);
		}

		movContext?.setFiltersMovTable(serverFilters);
		movContext?.setIsUpdatingFilters(true);
	}, [movementTypeFilter, stateFilter, myGlobalsFilters]);

	const handleStateChange = (event: SelectChangeEvent) => {
		setData([]);
		const status = String(event.target.value);
		setStateFilter(status === '0' ? undefined : status);
	};

	const resetFiltersFN = () => {
		table.setColumnFilters([]);
		table.setGlobalFilter('');
		setMovTypeFilter('');
		setStateFilter(0);
		setMyGlobalsFilters({});
		setDateRange([null, null]);
		table.setPageSize(10);
		const serverFilters: IFiltersMovementTable = {};
		serverFilters.page = movContext?.filtersMovTable?.page || 1;
		serverFilters.limit = movContext?.filtersMovTable?.limit || 10;
		movContext?.setFiltersMovTable(serverFilters);
		movContext?.setIsUpdatingFilters(true);
	};

	const handleAction = async (row: Row<any>, cell: Cell<any, any>) => {
		if (!row.original.button || !cell.id.includes('Acciones')) return;

		if (row.original.type === 'Alta') {
			if (row.original.button === 'approve') {
				setSelectedRow(row.original);
				setOpenModalApprove(true);
			}

			if (row.original.button === 'download-reception') {
				setSelectedRow(row.original);
				downloadReception(row.original);
			}

			if (row.original.button === 'edit') {
				setEditMovement(row.original);
				return;
			}

			if (row.original.button === 'delete') {
				setSelectedRow(row.original);
				setOpenModalDelete(true);
			}

			if (row.original.button === 'show') {
				if (!row.original) return;
				handleShow(row.original);
			}

			if (row.original.button === 'upload') {
				setSelectedRow(row.original);
				setOpenModalMinuteSigned(true);
			}

			if (row.original.button === 'show-files') {
				setSelectedRow(row.original);
				setShowMovementFilesModal(true);
			}

			if (row.original.button === 'show-observations') {
				setSelectedRow(row.original);
				setShowObsertavionsHistory(true);
			}

			delete row.original.button;
		}

		if (row.original.type === 'Baja') {
			if (row.original.button === 'approve') {
				setIsDownMovement(true);
				setSelectedRow(row.original);
				setOpenModalApprove(true);
			}

			if (row.original.button === 'download-reception') {
				setSelectedRow(row.original);
				downloadReception(row.original);
			}

			if (row.original.button === 'upload') {
				setSelectedRow(row.original);
				setOpenModalMinuteSigned(true);
			}

			if (row.original.button === 'edit') {
				handleEditDeregistration(row.original);
			}

			if (row.original.button === 'delete') {
				setSelectedRow(row.original);
				setOpenModalDelete(true);
			}

			if (row.original.button === 'show') {
				handleShowDeregistration(row.original);
			}

			if (row.original.button === 'show-files') {
				setSelectedRow(row.original);
				setShowMovementFilesModal(true);
			}

			if (row.original.button === 'show-observations') {
				setSelectedRow(row.original);
				setShowObsertavionsHistory(true);
			}

			delete row.original.button;
		}
	};

	const downloadReception = (selectedRow: any) => {
		let user = '';
		let address = '';
		if (!selectedRow) return;

		if (false && selectedRow.property_registration?.high_type_id === PropertyType.Acquisition) {
			getAfipUser(selectedRow.property_registration?.single_tax_id as string).then((res) => {
				if (res.data?.persona?.tipoPersona === 'FISICA') {
					user = res.data?.persona?.nombre + ' ' + res.data?.persona?.apellido;
					address =
						res.data?.persona?.domicilio[0].direccion +
						' ' +
						res.data?.persona?.domicilio[0].localidad +
						' ' +
						res.data?.persona?.domicilio[0].descripcionProvincia;
				} else {
					user = res.data?.persona?.razonSocial;
					address =
						res.data?.persona?.domicilio[0].direccion +
						' ' +
						res.data?.persona?.domicilio[0].localidad +
						' ' +
						res.data?.persona?.domicilio[0].descripcionProvincia;
				}
				generatePdfMovement(
					selectedRow.id as number,
					Number(selectedRow.property_registration?.minute_number),
					user,
					address
				);
				return;
			});
		} else {
			generatePdfMovement(selectedRow.id as number, Number(selectedRow.property_registration?.minute_number));
		}
	};

	const filesList = useMemo(() => {
		if (!selectedRow) return [];

		return [
			...selectedRow.property_movements_files,
			...((selectedRow.type === 'Alta' ? selectedRow.property_registration : selectedRow.property_deregistration)
				?.files as Array<any>)
		].map((file) => {
			return {
				name: file.name,
				type: (file.file_movement_type || file.file_type)?.name,
				id: file.id,
				path: file.path
			};
		});
	}, [selectedRow]);

	useEffect(() => {
		const [start, end] = dateRange;

		movContext?.setIsUpdatingFilters(true);
		movContext?.setFiltersMovTable((curr: any) => ({
			...curr,
			date_movement: start && end ? dayjs(start).format('YYYY-MM-DD') : undefined,
			date_movement_end: start && end ? dayjs(end).format('YYYY-MM-DD') : undefined,
			page: 1
		}));
	}, [dateRange]);

	function reloadTableMovements() {
		movContext?.setIsUpdatingFilters(true);
		movContext?.setFiltersMovTable((curr: any) => ({
			...curr
		}));
	}

	const sortByDate = () => {
		setIsAscendantDate((curr) => !curr);

		const getDate = (dateStr: string) => {
			const [y, m, d] = dateStr.split('-');
			return new Date(Number(y), Number(m) - 1, Number(d.slice(0, 2))).getTime();
		};

		setData((currentData) =>
			[...currentData].sort((movA, movB) => {
				if (isAscendantDate) return getDate(movB.date_movement) - getDate(movA.date_movement);
				else return getDate(movA.date_movement) - getDate(movB.date_movement);
			})
		);
	};

	const sortByMinuteNumber = () => {
		setIsAscendanMinuteNum((curr) => !curr);

		const getYearMinuteNumber = (dateStr: string, minuteNumber: number) => {
			const [y] = dateStr.split('-');
			return Number(y) + minuteNumber;
		};

		setData((currentData) =>
			[...currentData].sort((movA, movB) => {
				const movementA = getYearMinuteNumber(
					movA.date_movement,
					Number(movA.property_registration?.minute_number || 0)
				);
				const movementB = getYearMinuteNumber(
					movB.date_movement,
					Number(movB.property_registration?.minute_number || 0)
				);

				if (isAscendanMinuteNum) return movementA - movementB;
				else return movementB - movementA;
			})
		);
	};

	const handleTheadClick = (header: any) => {
		const columnName = header.id;

		if (columnName === 'date_movement') sortByDate();
		if (columnName === 'minute_number') sortByMinuteNumber();
	};

	const handleFilterByApprobation = (value: boolean) => {
		setIsChecked(value);

		setData(
			movements.filter((mov) => {
				const files = mov.property_movements_files;
				return value
					? files.some((file: any) => file.file_type_id === 10)
					: files.every((file: any) => file.file_type_id !== 10);
			})
		);
	};

	return (
		<>
			{openModalMinuteSigned ? (
				<UploadMinuteSigned
					movementType={documentType}
					onSuccess={reloadTableMovements}
					openModal={openModalMinuteSigned}
					setOpenModal={setOpenModalMinuteSigned}
					selectedRow={selectedRow}
				/>
			) : null}

			{openModalApprove && !isDownMovement ? (
				<ApproveMovement
					onSuccess={reloadTableMovements}
					openModal={openModalApprove}
					setOpenModal={setOpenModalApprove}
					selectedRow={selectedRow}
					data={data}
					setData={setData}
				/>
			) : null}

			{openModalApprove && isDownMovement ? (
				<ApproveDownMovement
					onSuccess={reloadTableMovements}
					openModal={openModalApprove}
					setOpenModal={setOpenModalApprove}
					selectedRow={selectedRow}
					data={data}
					setData={setData}
					setIsDownMovement={setIsDownMovement}
				/>
			) : null}

			{openModalDelete ? (
				<DeleteMovement
					onSuccess={reloadTableMovements}
					openModal={openModalDelete}
					setOpenModal={setOpenModalDelete}
					selectedRow={selectedRow}
					data={data}
					setData={setData}
				/>
			) : null}

			{showMovementFilesModal && selectedRow ? (
				<FormDialog
					title="Archivos Adjuntos del Movimiento"
					content={
						<Box
							width="100%"
							padding={2}>
							{filesList.map((file, i) => {
								return (
									<Box
										key={file.id}
										sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
										<Typography
											fontWeight={500}
											variant="subtitle1">{`${i + 1} - ${file.name} (${file.type})`}</Typography>
										<Button
											title="Ver archivo"
											onClick={() => window.open(file.path)}>
											Ver archivo <OpenInBrowser />
										</Button>
									</Box>
								);
							})}

							{!filesList.length ? <p>El movimiento no posee archivos adjuntos.</p> : null}
						</Box>
					}
					actions={<></>}
					show={showMovementFilesModal}
					handleClose={() => {
						setShowMovementFilesModal(false);
						setSelectedRow(null);
					}}
				/>
			) : null}

			{showObservationsHistory && selectedRow ? (
				<FormDialog
					title="Histórico de Movimientos"
					content={<PropertyMovementTimeline movementId={selectedRow.id} />}
					actions={<></>}
					show={showObservationsHistory}
					handleClose={() => {
						setShowObsertavionsHistory(false);
						setSelectedRow(null);
					}}
				/>
			) : null}

			<>
				<div>
					<Typography
						sx={{ my: 2 }}
						fontWeight={600}
						variant="h5">
						Filtrar por:
					</Typography>

					<Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
						<FormControl fullWidth>
							<InputLabel htmlFor="stateFilter">Estado</InputLabel>
							<Select
								size="small"
								id="stateFilter"
								value={stateFilter}
								label="Estado"
								onChange={handleStateChange}>
								<MenuItem value={0}>Todos</MenuItem>
								<MenuItem value={MovementsStatus.PENDIENTE}>Pendiente</MenuItem>
								<MenuItem value={MovementsStatus.APROBADO}>Aprobado</MenuItem>
								<MenuItem value={MovementsStatus.ENVIADO}>Enviado</MenuItem>
								<MenuItem value={MovementsStatus.OBSERVADO}>Observado</MenuItem>
								<MenuItem value={MovementsStatus.RECTIFICADO}>Rectificado</MenuItem>
								<MenuItem value={MovementsStatus.CONSOLIDADO}>Consolidado</MenuItem>
							</Select>
						</FormControl>

						<Box
							width="100%"
							sx={{ display: 'flex', alignItems: 'center', maxWidth: '250px' }}>
							<Typography
								sx={{ flexShrink: 0, mr: 1 }}
								fontSize={16}
								variant="h6">
								Acta firmada
							</Typography>
							<Switch
								onChange={handleFilterByApprobation}
								value={isChecked}
							/>
						</Box>

						<Box width="100%">
							<AppDateRangePicker
								disableFuture
								size="small"
								value={dateRange}
								onChange={setDateRange}
							/>
						</Box>

						<FormControl
							fullWidth
							sx={{ maxWidth: '160px' }}>
							{<InputLabel id="select-label">Cantidad de resultados</InputLabel>}
							<Select
								size="small"
								label="Cantidad de resultados"
								value={table.getState().pagination.pageSize}
								onChange={(e) => {
									table.setPageSize(Number(e.target.value));
									movContext?.setMeta({ ...movContext?.meta, itemsPerPage: Number(e.target.value) });
									movContext?.setFiltersMovTable({
										...movContext?.filtersMovTable,
										limit: Number(e.target.value),
										page: 1
									});
									movContext?.setIsUpdatingFilters(true);
								}}>
								{[10, 20, 30, 40, 50].map((pageSize) => (
									<MenuItem
										key={pageSize}
										value={pageSize}>
										Mostrar {pageSize}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Button
							sx={{ flexShrink: 0 }}
							size="small"
							onClick={resetFiltersFN}
							variant="contained">
							Limpiar
							<ClearIcon />
						</Button>
					</Box>

					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							my: 1
						}}>
						<Typography variant="h6">{`Total de Movimientos: ${movContext?.meta.totalItems || 0}`}</Typography>
					</Box>
				</div>

				<table className="movements-table">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<th
											key={header.id}
											onClick={() => handleTheadClick(header)}
											colSpan={header.colSpan}>
											{header.isPlaceholder ? null : (
												<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
											)}
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					{!promiseInProgress && (
						<tbody>
							{table.getRowModel().rows.map((row) => {
								return (
									<tr key={row.id}>
										{row.getVisibleCells().map((cell) => {
											return (
												<td
													onClick={() => handleAction(row, cell)}
													key={cell.id}>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</td>
											);
										})}
									</tr>
								);
							})}

							{data.length === 0 ? (
								<tr>
									<td
										colSpan={columns.length}
										style={{
											paddingTop: '10px',
											paddingBottom: '10px'
										}}>
										<div>{`No se encontraron resultados en la búsqueda realizada`}</div>
									</td>
								</tr>
							) : null}
						</tbody>
					)}
				</table>
				<div style={{ display: 'flex', justifyContent: 'center', minHeight: promiseInProgress ? '250px' : '100%' }}>
					<TablePaginator table={table} />
				</div>
			</>
		</>
	);
};
