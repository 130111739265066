import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { GenericFieldDetail } from 'components/globals/fields/FieldDetail';
import { PROPERTY_TYPE, REGISTER_TYPES, TypeOfVoucher } from 'enums';
import { FieldDate } from 'components/globals/fields/FieldDate';
import { tryGetAllPatrimonialDependences } from 'services/patrimonialDependencesService';
import { parsedFormatDate } from 'helpers/Formaters/Formaters';
import { Box } from '@mui/system';

interface ISummaryStepOne {
	entity: any;
}

const dates = {
	1: 'date_voucher',
	2: 'date_transfer',
	3: 'date_donation'
};

export const SummaryStepOne = ({ entity }: ISummaryStepOne) => {
	const [dependenceName, setDependenceName] = useState('-');

	const documentTypes = [
		...new Set(
			(entity.properties_items || []).map((el: any) => {
				return PROPERTY_TYPE.find((type) => type.id === el.property_type_id)?.label;
			})
		)
	].join(', ');

	useEffect(() => {
		(async function () {
			const data = await tryGetAllPatrimonialDependences();

			setDependenceName(
				data.find((el: any) => el.id === entity.to_patrimonial_dependence_id)?.patrimonial_dependence || '-'
			);
		})();
	}, [entity]);

	return (
		<Grid
			container
			spacing={1}
			sx={{ padding: '4%' }}>
			<GenericFieldDetail
				label="Tipo de Alta:"
				value={REGISTER_TYPES.find((el) => el.id === entity.high_type_id)?.label}
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label="Tipo de Bien:"
				value={documentTypes || '-'}
			/>
			<Divider className="customDivider" />

			{/* ADQUISITION */}
			{entity.high_type_id === 1 ? (
				<>
					{entity.property_type_id === 3 ? (
						<>
							<GenericFieldDetail
								label="Fecha de Alta:"
								value={parsedFormatDate(entity.date_voucher)}
							/>
							<Divider className="customDivider" />
						</>
					) : (
						<Grid
							container
							mt={1}
							marginLeft={0}
							paddingLeft={0}
							spacing={2}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								sx={{ paddingLeft: '0 !important' }}>
								<Typography
									fontSize={16}
									variant="h6">
									Comprobante Fiscal:
								</Typography>
							</Grid>

							<GenericFieldDetail
								label="Tipo de Comprobante:"
								value={TypeOfVoucher[entity.voucher_type_id as keyof typeof TypeOfVoucher] || '-'}
							/>
							<Divider className="customDivider" />

							<GenericFieldDetail
								label="Punto de Venta:"
								value={entity.invoice_number.split('-')[0]}
							/>
							<Divider className="customDivider" />

							<GenericFieldDetail
								label="N° de Comprobante:"
								value={entity.invoice_number.split('-')[1]}
							/>
							<Divider className="customDivider" />

							<GenericFieldDetail
								label="Fecha del Comprobante:"
								value={parsedFormatDate(entity.date_voucher)}
							/>
							<Divider className="customDivider" />
						</Grid>
					)}

					<Grid
						container
						mt={1}
						marginLeft={0}
						paddingLeft={0}
						spacing={2}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							sx={{ paddingLeft: '0 !important' }}>
							<Typography
								fontSize={16}
								variant="h6">
								Proveedor:
							</Typography>
						</Grid>

						<GenericFieldDetail
							label="CUIT/CUIL:"
							value={entity.single_tax_id}
						/>
						<Divider className="customDivider" />

						<GenericFieldDetail
							label="Razón Social/Nombre y Apellido:"
							value={entity.afip_data?.business_name}
						/>
						<Divider className="customDivider" />

						<GenericFieldDetail
							label="Dirección:"
							value={entity.afip_data?.fiscal_address.address}
						/>
						<Divider className="customDivider" />
					</Grid>
				</>
			) : null}

			{/* TRANSFERENCE */}
			{entity.high_type_id === 2 ? (
				<>
					<GenericFieldDetail
						label="Dependencia Patrimonial:"
						value={dependenceName}
					/>
					<Divider className="customDivider" />

					<GenericFieldDetail
						label="Fecha de la Transferencia:"
						value={parsedFormatDate(entity.date_transfer)}
					/>
					<Divider className="customDivider" />
				</>
			) : null}

			{/* DONATION */}
			{entity.high_type_id === 3 ? (
				<>
					<GenericFieldDetail
						label="Fecha de Donación:"
						value={parsedFormatDate(entity.date_donation)}
					/>
					<Divider className="customDivider" />

					<Grid
						container
						mt={1}
						marginLeft={0}
						paddingLeft={0}
						spacing={2}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							sx={{ paddingLeft: '0 !important' }}>
							<Typography
								fontSize={16}
								variant="h6">
								Donatario:
							</Typography>
						</Grid>

						<GenericFieldDetail
							paddingLeft="0px"
							label={entity.afip_data?.is_dni ? 'N° de DNI:' : 'CUIT/CUIL:'}
							value={entity.single_tax_id}
						/>
						<Divider className="customDivider" />

						<GenericFieldDetail
							label="Razón Social/Nombre y Apellido:"
							value={entity.afip_data?.business_name || entity?.donor_name}
						/>
						<Divider className="customDivider" />

						<GenericFieldDetail
							label="Dirección:"
							value={entity.afip_data?.fiscal_address.address}
						/>
						<Divider className="customDivider" />
					</Grid>
				</>
			) : null}
		</Grid>
	);
};
