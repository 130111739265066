/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Typography, useRadioGroup } from '@mui/material';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { IAltaStepOne } from 'interfaces/alta/IAlta';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { Adquisicion, IAdquisicionValues, IEntityValues, IVoucherValues } from './adquisicion/Adquisicion';
import { ITransferenceValues, Transferencia } from './transferencia/Transferencia';
import { Donacion, IDonacionValues } from './donacion/Donacion';
import { Select } from 'components/globals/select/Select';
import { getFilesByDocumentId } from 'services/altaService';
import { Attachment } from 'interfaces/file/IFile';
import { PROPERTY_TYPE, PROPERTY_TYPE_IDS, REGISTER_TYPES_ID } from 'enums';

export interface IOverviewAltaStepOneForm {
	entity?: IAltaStepOne;
	handleSubmit(data: IAltaStepOne): void;
	handleCancel(hasPendingData: boolean): void;
	buttonLabelCancel?: string;
	buttonLabelSubmit?: string;
	registerTypes: ISelectOption[];
	itemTypes: ISelectOption[];
	onChangeItemType: CallableFunction;
	movementStatusId?: number;
	registrationId?: number;
}

export const LABELS_STEP_ONE_FORM = {
	registerType: 'Tipo de Alta',
	itemType: 'Tipo de Bien'
};

const primaryFields: { [key: number]: Array<keyof IAltaStepOne> } = {
	1: ['date_voucher', 'single_tax_id', 'invoice_number'],
	2: ['to_patrimonial_dependence_id', 'date_transfer'],
	3: ['date_donation', 'single_tax_id', 'donor_name']
};

const getPrimaryData = (formData: IAltaStepOne, type: number) => {
	if (!type) return {};

	return primaryFields[type].reduce((result, field) => {
		const val = formData[field];

		if (typeof val === 'string') {
			(result[field] as string) = val;
		}

		if (typeof val === 'undefined') {
			(result[field] as undefined) = val;
		}

		if (typeof val === 'number') {
			(result[field] as number) = val;
		}

		if (typeof val === 'boolean') {
			(result[field] as boolean) = val;
		}

		return result;
	}, {} as IAltaStepOne);
};

const isFullfiled = (obj: Record<string, unknown>) => {
	const values = Object.values(obj);

	if (!values.length) return true;

	return values.every((val) => !!val);
};

export const AltaStepOneForm = ({
	entity,
	handleSubmit,
	handleCancel,
	buttonLabelCancel = 'Cancelar',
	buttonLabelSubmit = 'Siguiente',
	registerTypes,
	itemTypes,
	onChangeItemType,
	movementStatusId,
	registrationId
}: IOverviewAltaStepOneForm) => {
	const registerTypesOptions = [{ label: 'Seleccione Tipo de Alta', id: 0, disabled: true }, ...registerTypes];
	const itemTypesOptions = [{ label: 'Seleccione Tipo de Bien', id: 0, disabled: true }, ...itemTypes];
	const [registerType, setRegisterType] = useState<ISelectOption>(registerTypesOptions[0]);
	const [itemType, setItemType] = useState<ISelectOption | undefined>(
		JSON.parse(localStorage.getItem('SELECTED_ITEM_TYPE') as string) || itemTypesOptions[0]
	);
	const [adquisicion, setAdquisicion] = useState<IAdquisicionValues | undefined>();
	const [transferencia, setTransferencia] = useState<ITransferenceValues | undefined>();
	const [donacion, setDonacion] = useState<IDonacionValues | undefined>();

	const [isEnableContinue, setIsEnableContinue] = useState<boolean>(false);
	const [dataFormHasChanged, setDataFormHasChanged] = useState<number>(0);
	const [selectedFiles, setSelectedFiles] = useState<Attachment[]>([]);
	const [isFetchingFiles, setIsFetchingFiles] = useState(false);

	const [dateError, setDateError] = useState<string>('');

	const getFormData = (): IAltaStepOne => {
		if (registerType?.id === 1) {
			const payload = {
				id: entity?.id,
				property_type_id: itemType?.id,
				single_tax_id: adquisicion?.entity?.identifier || adquisicion?.entity?.dni,
				invoice_number:
					adquisicion?.voucher?.pointSale && adquisicion?.voucher?.number
						? adquisicion?.voucher?.pointSale + '-' + adquisicion?.voucher?.number
						: '',
				high_type_id: registerType?.id || 0,
				voucher_type_id: adquisicion?.voucher?.type,
				date_voucher: adquisicion?.voucher?.voucherDate,
				date_legal_instrument: adquisicion?.voucher?.legalInstrumentDate,
				description: adquisicion?.voucher?.observations,
				voucherFile: adquisicion?.voucher?.attachedVoucher,
				legalInstrumentFile:
					adquisicion?.voucher?.attachedLegalInstrument ||
					selectedFiles.find(({ file_type_id }) => file_type_id === 2 || file_type_id === 3)
			} as IAltaStepOne;

			payload.provider_data = {
				business_name: adquisicion?.entity?.name || '',
				fiscal_address: adquisicion?.entity?.address || '',
				has_error_afip: !!adquisicion?.entity?.hasErrorAFIP,
				is_dni: !!adquisicion?.entity?.dni
			};

			if (payload.property_type_id === PROPERTY_TYPE_IDS.INMUEBLE) {
				delete payload.invoice_number;
				delete payload.voucher_type_id;
				delete payload.voucherFile;
			}

			return payload;
		}

		if (registerType?.id === 2) {
			return {
				id: entity?.id,
				property_type_id: itemType?.id || null,
				high_type_id: registerType?.id || 0, //Tipo de alta
				date_transfer: transferencia?.date,
				date_legal_instrument: transferencia?.dateIntrument,
				legalInstrumentFile:
					transferencia?.attached || selectedFiles.find(({ file_type_id }) => file_type_id === 2 || file_type_id === 3),
				description: transferencia?.observations,
				to_patrimonial_dependence_id: transferencia?.patrimonialDependence || 0 //Id de Dependencia Patrimonial
			} as IAltaStepOne;
		}

		if (registerType?.id === 3) {
			const payload = {
				id: entity?.id,
				property_type_id: itemType?.id,
				high_type_id: registerType?.id || 0,
				donor_name: donacion?.entity?.name || donacion?.entity?.donor_name,
				donor_address: donacion?.entity?.address,
				date_donation: donacion?.date,
				date_legal_instrument: donacion?.dateIntrument,
				legalInstrumentFile:
					donacion?.attached || selectedFiles.find(({ file_type_id }) => file_type_id === 2 || file_type_id === 3),
				description: donacion?.observations,
				single_tax_id: donacion?.entity?.documentType === "DNI" ? donacion?.entity?.dni || adquisicion?.entity?.identifier || adquisicion?.entity?.dni : adquisicion?.entity?.identifier || adquisicion?.entity?.dni,
			} as IAltaStepOne;

			payload.provider_data = {
				business_name: donacion?.entity?.name || '',
				fiscal_address: donacion?.entity?.address || '',
				has_error_afip: !!donacion?.hasErrorAFIP || !!donacion?.entity?.dni,
				is_dni: !!donacion?.entity?.dni
			};

			return payload;
		}

		return {} as IAltaStepOne;
	};

	const currentFormData = useMemo(() => {
		return getFormData();
	}, [registerType, adquisicion, transferencia, donacion, dateError, itemType, selectedFiles]);

	const isValidFormData = useMemo(() => {
		const formData = getFormData();

		const primaryData = getPrimaryData(formData, registerType.id as number) as Record<string, unknown>;

		let documentType = '';
		let isValidInvoiceNumber = true;

		if (registerType?.id === REGISTER_TYPES_ID.ACQUISITION) {
			if (
				(adquisicion?.voucher?.number?.length !== 8 ||
					(adquisicion?.voucher?.pointSale && adquisicion?.voucher?.pointSale?.length < 4)) &&
				itemType?.id !== PROPERTY_TYPE_IDS.INMUEBLE
			) {
				return false;
			}
			const [pointSale, invoiceNumber] = (formData.invoice_number || '').split('-');

			if (itemType?.id !== PROPERTY_TYPE_IDS.INMUEBLE) {
				isValidInvoiceNumber = !!(Number(pointSale) && Number(invoiceNumber));
			}

			documentType = adquisicion?.entity?.documentType ?? 'CUIT/CUIL';
		} else if (registerType?.id === REGISTER_TYPES_ID.DONATION) {
			documentType = donacion?.entity?.documentType ?? 'CUIT/CUIL';

		} else {
			documentType = '';
		}

		const { date_legal_instrument, description, legalInstrumentFile } = formData;
		const secondaryData = {} as { [k: string]: unknown };

		secondaryData.description = description;
		secondaryData.legalInstrumentFile = legalInstrumentFile;

		if (dateError === 'timeless') secondaryData.date_legal_instrument = date_legal_instrument;

		if (itemType?.id === PROPERTY_TYPE_IDS.INMUEBLE) {
			delete primaryData.invoice_number;
		}

		let providerAndDonorValidation = true;
		if (
			documentType == 'CUIT/CUIL' &&
			registerType?.id == REGISTER_TYPES_ID.DONATION &&
			(formData?.donor_name?.trim() == '' ||
				formData?.donor_address?.trim() == '' ||
				formData?.donor_name === undefined ||
				formData?.donor_address === undefined)
		) {
			providerAndDonorValidation = false;
		} else if (
			documentType == 'CUIT/CUIL' &&
			registerType?.id == REGISTER_TYPES_ID.ACQUISITION &&
			(formData?.provider_data?.business_name?.trim() == '' ||
				formData?.provider_data?.fiscal_address?.trim() == '' ||
				formData?.provider_data?.business_name === undefined ||
				formData?.provider_data?.fiscal_address === undefined)
		) {
			providerAndDonorValidation = false;
		} else if (documentType == 'No requerido') {
			providerAndDonorValidation = true;
			primaryData.single_tax_id = 'No requerido';
		}

		if (!dateError) {
			delete secondaryData.description;
			delete secondaryData.legalInstrumentFile;
		}
		if (registerType?.id === REGISTER_TYPES_ID.TRANSFERENCE) {
			return isFullfiled(primaryData) && providerAndDonorValidation && isValidInvoiceNumber;
		}
		console.log(isFullfiled(primaryData), isFullfiled(secondaryData), providerAndDonorValidation, isValidInvoiceNumber);
		return isFullfiled(primaryData) && isFullfiled(secondaryData) && providerAndDonorValidation && isValidInvoiceNumber;
	}, [registerType, adquisicion, transferencia, donacion, dateError, itemType, selectedFiles]);

	useEffect(() => {
		if (!entity) return;

		const userEntity = {
			identifier: entity?.single_tax_id,
			name: [entity.afip_data?.name, entity.afip_data?.last_name].filter((val) => val).join(' '),
			address: entity?.afip_data?.fiscal_address.address,
			isDni: entity.afip_data?.is_dni,
			donor_name: entity?.donor_name
		};

		if (entity.high_type_id === 1) {
			const [pointSale, number] = ((entity.invoice_number as string) || '').split('-');

			setAdquisicion({
				entity: userEntity,
				voucher: {
					type: entity.voucher_type_id,
					number,
					pointSale,
					attachedVoucher: entity.voucherFile,
					voucherDate: entity.date_voucher,
					attachedLegalInstrument: entity.legalInstrumentFile,
					legalInstrumentDate: entity.date_legal_instrument,
					observations: entity.description
				} as IVoucherValues
			} as IAdquisicionValues);
		}

		if (entity.high_type_id === 2) {
			setTransferencia({
				id: entity.id,
				patrimonialDependence: entity.to_patrimonial_dependence_id,
				date: entity.date_transfer,
				dateIntrument: entity.date_legal_instrument,
				observations: entity.description,
				attached: undefined
			} as ITransferenceValues);
		}

		if (entity.high_type_id === 3) {
			setDonacion({
				entity: userEntity,
				date: entity.date_donation,
				dateIntrument: entity.date_legal_instrument,
				observations: entity.description
			} as IDonacionValues);
			setIsEnableContinue(true);
		}

		const finded = registerTypes.find((rt) => {
			return rt.id == entity?.high_type_id;
		});

		if (finded) setRegisterType(finded);

		const propertyType = PROPERTY_TYPE.find((rt) => {
			return rt.id == entity?.property_type_id;
		});

		if (propertyType) setItemType(propertyType);
	}, [entity]);

	const onHandleSubmit = (): void => {
		if (!itemType) return;
		handleSubmit(getFormData());
	};

	const fetchFiles = async () => {
		if (isFetchingFiles || !registrationId) return;

		try {
			setIsFetchingFiles(true);
			const stepOneData = JSON.parse(localStorage.getItem('STORAGED_REGISTER_STEP_ONE') || 'null');

			if (stepOneData) {
				const data = await getFilesByDocumentId(stepOneData?.id || registrationId);
				setSelectedFiles(data);
			}
		} finally {
			setIsFetchingFiles(false);
		}
	};

	useEffect(() => {
		if (registerType.id === 1 && itemType?.id === 3) {
			setAdquisicion((curr) => ({ ...curr, voucher: { ...curr?.voucher, attachedVoucher: undefined } }));
		}
	}, [itemType, registerType]);

	useEffect(() => {
		fetchFiles();
	}, [currentFormData.legalInstrumentFile, currentFormData.voucherFile]);

	return (
		<div className="borderedBox">
			<Grid
				container
				spacing={2}
				pt={2}>
				<Grid
					item
					xs={6}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<Box borderBottom="1px">
						<Typography
							variant="subtitle2"
							style={{ paddingLeft: '16px' }}
							borderBottom="1px solid"
							borderColor="primary"
							paddingBottom="4px"
							mb={2}>
							Tipo de Alta
						</Typography>
						<Select
							disabled={Boolean(movementStatusId && movementStatusId !== 1)}
							options={registerTypesOptions}
							value={registerType}
							onChange={(val) => {
								setRegisterType(val as ISelectOption);
							}}
						/>
					</Box>
				</Grid>

				<Grid
					item
					xs={6}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<Box borderBottom="1px">
						<Typography
							variant="subtitle2"
							style={{ paddingLeft: '16px' }}
							borderBottom="1px solid"
							borderColor="primary"
							paddingBottom="4px"
							mb={2}>
							Tipo de Bien
						</Typography>

						<Select
							value={itemType}
							options={itemTypesOptions}
							onChange={(val) => {
								onChangeItemType(val);
								setItemType(val as ISelectOption);
							}}
						/>
					</Box>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					{registerType?.id === 1 && (
						<>
							<Adquisicion
								movementStatusId={movementStatusId}
								onTimelessError={setDateError}
								onDeleteFile={fetchFiles}
								attachments={selectedFiles}
								itemTypeId={itemType?.id}
								entity_id={entity?.id}
								entity={adquisicion as IAdquisicionValues}
								handleChange={(data: IAdquisicionValues | undefined = undefined) => {
									setAdquisicion(data);
									setDataFormHasChanged(dataFormHasChanged + 1);
								}}
							/>
						</>
					)}

					{registerType?.id === 2 && (
						<>
							<Transferencia
								onTimelessError={setDateError}
								onDeleteFiles={fetchFiles}
								attachments={selectedFiles}
								documentId={entity?.id}
								entity={transferencia as ITransferenceValues}
								handleChange={(data: ITransferenceValues | undefined = undefined) => {
									setTransferencia(data);
									setDataFormHasChanged(dataFormHasChanged + 1);
								}}
							/>
						</>
					)}

					{registerType?.id === 3 && (
						<>
							<Donacion
								onTimelessError={setDateError}
								onDeleteFile={fetchFiles}
								attachments={selectedFiles}
								entity={donacion as IDonacionValues}
								handleChange={(data?: IDonacionValues) => {
									setDonacion(data);
									setDataFormHasChanged(dataFormHasChanged + 1);
								}}
							/>
						</>
					)}
				</Grid>

				<Typography
					variant="subtitle2"
					style={{ width: '100%' }}
					pl={1}
					mt={1}>{`(*) Campos Obligatorios.`}</Typography>
			</Grid>

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					variant="contained"
					onClick={() => {
						handleCancel(!isEnableContinue);
					}}>
					{buttonLabelCancel}
				</ButtonDecline>
				<ButtonConfirm
					disabled={!itemType?.id || !registerType?.id || !isValidFormData}
					sx={{ ml: 3 }}
					variant="contained"
					onClick={onHandleSubmit}>
					{buttonLabelSubmit}
				</ButtonConfirm>
			</Box>
		</div>
	);
};
