import { FC, useState, useEffect } from 'react';

//Components
import { ComprobanteComponent, IComprobante } from './Ncomprobante';
import { SearchEntityComponent, ISearchEntityData } from 'components/globals/searchEntity/SearchEntity';
import { FechaFactRemito, IFechaComprobante } from '../FechaFactRemito';

// Material UI
import { Grid } from '@mui/material';
import { Attachment } from 'interfaces/file/IFile';
export interface IEntityValues {
	identifier?: string;
	name?: string;
	address?: string;
	hasErrorAFIP?: boolean;
	dni?: string;
	isDni?: boolean;
	documentType?: string | undefined;
}

export interface IVoucherValues {
	type?: string;
	number?: string;
	pointSale?: string;
	attachedVoucher?: File;
	voucherDate?: string;
	attachedLegalInstrument?: File;
	legalInstrumentDate?: string;
	observations?: string;
}

export interface IAdquisicionValues {
	entity?: IEntityValues;
	voucher?: IVoucherValues;
	hasErrors?: boolean;
	errors?: any;
}

export interface IOverviewAdquisicionForm {
	entity_id?: number;
	entity: IAdquisicionValues;
	handleChange: (data: IAdquisicionValues) => void;
	itemTypeId?: number;
	attachments?: Attachment[];
	onDeleteFile?(): void;
	movementStatusId?: number;
	onTimelessError?: CallableFunction;
}

export interface IErrorsAdquisicionForm {
	user?: string | undefined;
}

const Adquisicion: FC<IOverviewAdquisicionForm> = ({
	entity_id,
	entity,
	itemTypeId,
	handleChange,
	attachments,
	onDeleteFile,
	movementStatusId,
	onTimelessError
}: IOverviewAdquisicionForm) => {
	// Persona u Organismo
	const [user, setUser] = useState<ISearchEntityData | undefined>();

	// Comprobante Fiscal
	const [voucher, setVoucher] = useState<IComprobante | undefined>();
	const [voucherDetails, setVoucherDetails] = useState<IFechaComprobante | undefined>();

	// Parámetros generales
	const voucherTypes = [
		{ label: 'Factura', id: 1 },
		{ label: 'Remito', id: 2 }
	];

	const [errors, setErrors] = useState<IErrorsAdquisicionForm>({} as IErrorsAdquisicionForm);

	useEffect(() => {
		if (!entity || (voucher && voucherDetails)) return;

		setUser({
			identifier: entity.entity?.identifier,
			name: entity.entity?.name,
			address: entity.entity?.address,
			user: {
				domicilio: entity.entity?.address || '',
				nombre: entity.entity?.name || ''
			},
			hasErrorAFIP: entity.entity?.hasErrorAFIP
		} as ISearchEntityData);

		setVoucher({
			tipo: entity.voucher?.type,
			numero: entity.voucher?.number,
			puntoVenta: entity.voucher?.pointSale,
			adjunto: entity.voucher?.attachedVoucher
		} as IComprobante);

		setVoucherDetails({
			date: entity.voucher?.voucherDate,
			observations: entity.voucher?.observations,
			attached: entity.voucher?.attachedLegalInstrument,
			dateIntrument: entity.voucher?.legalInstrumentDate
		} as IFechaComprobante);
	}, [entity]);

	const softValidateForm = (): boolean => {
		if (user?.identifier == undefined || user.name == undefined || user.address == undefined) {
			return false;
		}

		if (
			voucher?.tipo == undefined ||
			voucher.numero == undefined ||
			voucher.puntoVenta == undefined ||
			voucher.adjunto == undefined
		) {
			return false;
		}

		if (voucherDetails?.date == undefined) {
			return false;
		}

		return true;
	};

	const getParsedData = (hasErrors: boolean): IAdquisicionValues => {
		return {
			entity: { ...user },
			voucher: {
				type: voucher?.tipo,
				number: voucher?.numero,
				pointSale: voucher?.puntoVenta,
				voucherDate: voucherDetails?.date,
				attachedVoucher: voucher?.adjunto,
				legalInstrumentDate: voucherDetails?.dateIntrument,
				attachedLegalInstrument: voucherDetails?.attached,
				observations: voucherDetails?.observations
			} as IVoucherValues,
			hasErrors: hasErrors,
			errors: errors
		} as IAdquisicionValues;
	};

	useEffect(() => {
		const hasErrors = !softValidateForm();
		handleChange(getParsedData(hasErrors));
	}, [errors]);

	return (
		<Grid
			container
			spacing={2}
			className={'mt-2'}>
			<SearchEntityComponent
				disableDni
				registerTypeId={1}
				identifier={user?.identifier}
				name={user?.name}
				address={user?.address}
				user={user?.user}
				donor_name={user?.donor_name}
				handleChange={(data: ISearchEntityData) => {
					setUser({
						identifier: data.identifier,
						name: data.name,
						address: data.address,
						user: data.user,
						hasErrorAFIP: data.hasErrorAFIP,
						dni: data.dni,
						documentType: data.documentType
					} as ISearchEntityData);
					setErrors({ ...data.errors });
				}}
			/>

			{itemTypeId !== 3 ? (
				<ComprobanteComponent
					onDeleteFile={onDeleteFile}
					attachments={attachments?.filter((el) => el.file_type_id === 4)}
					entity_id={entity_id}
					tipo={voucher?.tipo || 0}
					numero={voucher?.numero}
					adjunto={voucher?.adjunto}
					puntoVenta={voucher?.puntoVenta}
					comprobanteList={voucherTypes}
					handleChange={(data: IComprobante) => {
						setVoucher({
							tipo: data.tipo,
							numero: data.numero,
							puntoVenta: data.puntoVenta,
							adjunto: data.adjunto
						} as IComprobante);
						setErrors({ ...data.errors });
					}}
				/>
			) : null}

			<FechaFactRemito
				disabled={Boolean(movementStatusId && movementStatusId !== 1)}
				onTimelessError={onTimelessError}
				onDeleteFiles={onDeleteFile}
				attachments={attachments?.filter((el) => el.file_type_id === 2 || el.file_type_id === 3)}
				showTitle
				title={itemTypeId === 3 ? 'Fecha de Alta' : ''}
				entity_id={entity_id}
				date={voucherDetails?.date}
				observations={voucherDetails?.observations}
				attached={voucherDetails?.attached}
				dateIntrument={voucherDetails?.dateIntrument}
				handleChange={(data: IFechaComprobante) => {
					setVoucherDetails({
						date: data.date,
						observations: data.observations,
						attached: data.attached,
						dateIntrument: data.dateIntrument
					} as IFechaComprobante);
					setErrors({ ...data.errors });
				}}
			/>
		</Grid>
	);
};

Adquisicion.displayName = 'Adquisicion';

export { Adquisicion };
